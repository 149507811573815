import React from 'react'
import image1 from '../../images/cariersservices/icon.svg'
import image2 from '../../images/cariersservices/icon_1.svg'
import image3 from '../../images/cariersservices/icon_2.svg'
import image4 from '../../images/cariersservices/icon_3.svg'
import image5 from '../../images/cariersservices/icon_4.svg'
import image6 from '../../images/cariersservices/icon_5.svg'

const Services = ({title, desc, border}) => {
  return (
    <section className={border ? "co-services typical__padding co-border co-bg--grey": "co-services typical__padding co-bg--grey"}>
    <div className="section-container">
        <h2 className="section-title">
            {title}
        </h2>
        {desc && <p className="section-description">{desc}</p>}

        <div className="cards-list">
            <div className="cards-list--card">
                <div className="cards-list--card-content">
                    <div className="icon">
                        <img  alt="Digimeth Family" src={image1} />
                    </div>
                    <h3 className="title">Digimeth Family</h3>
                    <p className="text">In the friendly working environment of Digimeth family</p>
                </div>
            </div>
            <div className="cards-list--card">
                <div className="cards-list--card-content">
                    <div className="icon">
                        <img   alt="Digimeth Family" src={image2} />
                    </div>
                    <h3 className="title">Team Activities</h3>
                    <p className="text">Adoring your workplace and team activities</p>

                </div>
            </div>

            <div className="cards-list--card">
                <div className="cards-list--card-content">
                    <div className="icon">
                    <img   alt="Digimeth Family" src={image3} />
                    </div>
                    <h3 className="title">Social Responsibility</h3>
                    <p className="text">Supporting environmental integrity and social responsibility</p>
                </div>
            </div>

            <div className="cards-list--card">
                <div className="cards-list--card-content">
                    <div className="icon">
                    <img   alt="Digimeth Family" src={image4} />
                    </div>
                    <h3 className="title">Personal Development</h3>
                    <p className="text">With a space for creativity and personal development</p>
                </div>
            </div>
            <div className="cards-list--card">
                <div className="cards-list--card-content">
                    <div className="icon">
                    <img   alt="Digimeth Family" src={image5} />
                    </div>
                    <h3 className="title">Educational Programs</h3>
                    <p className="text">Attending different conferences and educational programs</p>
                </div>
            </div>

            <div className="cards-list--card">
                <div className="cards-list--card-content">
                    <div className="icon">
                    <img   alt="Digimeth Family" src={image6} />
                    </div>
                    <h3 className="title">Insurance Coverage</h3>
                    <p className="text">Doing sports and having A+ insurance coverage</p>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Services