import React from 'react'
import ContactForm from '../../ui/form/ContactForm'

const GetStarted = ({isGetStarted, title, description}) => {
  return (
    <section id="contact" className="getstart typical__padding">
        <div className="site__container">
          <ContactForm isGetStarted={isGetStarted} title={title} description={description}/>
        </div>
    </section>
  )
}

export default GetStarted