import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Services from "../components/sections/Services";
import GetStarted from "../components/sections/GetStarted";
import CarouselHorVert from "../components/sections/CarouselHorVert";

// import picture1 from "../images/team1.png";
// import picture2 from "../images/team2.png";
// import picture3 from "../images/team3.png";
// import picture4 from "../images/team4.png";

// import picture6 from "../images/team4.png";
// import picture7 from "../images/team4.png";
// import picture8 from "../images/team4.png";


import contactus_1 from '../images/contact-section-icons/briefcase.svg';
import contactus_2 from '../images/contact-section-icons/location.svg';
import contactus_3 from '../images/contact-section-icons/messages.svg';

import image from '../images/about-group.png';

const AboutPage = () => {
  return (
    <Layout mainbg={`co-main-dark`}>
      <SEO title={`Who we are - Digimeth.net`} />
      <article>
        <section className="black-hero about_page">
          <div className="site__container">
            <div className="middle-wrapper typical__padding">
              <h1 className="middle-wrapper--title">Digimeth!</h1>
              <p className="middle-wrapper--description">
                Benefit from smart payment solutions!
              </p>
              <img alt="about-group" src={image}/>
            </div>
          </div>
        </section>

        <section className="alternative_payment-section typical__padding co-bg--white">
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="colorized mint">We are a team </span>
                    <br />
                    <span className="default"> of payment technologies!</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="right">
                <div className="description_box green-border">
                <p className="description--description  green-border">
                Our mission is to provide an extremely fast, high-quality and exciting online payment experience to our main friends - our customers
                </p>
              </div>
            </div>
          </div>
        </section>


        <section className="team__section typical__padding co-bg--grey">
          {/* <div className="site__container ">
            <div className="team-wrapper">
              <div className="team-wrapper--grid">
                <div className="team-wrapper--card">
                  <div className="team-wrapper--card-content">
                    <img src={picture1} alt="team" />
                  </div>
                </div>
                <div className="team-wrapper--card">
                  <div className="team-wrapper--card-content">
                    <img src={picture2} alt="team" />
                  </div>
                </div>
                <div className="team-wrapper--card">
                  <div className="team-wrapper--card-content">
                    <img src={picture3} alt="team" />
                  </div>
                </div>
                <div className="team-wrapper--card">
                  <div className="team-wrapper--card-content">
                    <img src={picture4} alt="team" />
                  </div>
                </div>

                <div className="team-wrapper--card">
                  <div className="team-wrapper--card-content">
                    <div className="team-wrapper--card-content-wrapper">
                      <h3 className="team-wrapper--card-content-wrapper-title">
                        <span>We are a team</span>
                        <span>of payment technologies!</span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="team-wrapper--card">
                  <div className="team-wrapper--card-content">
                    <img src={picture6} alt="team" />
                  </div>
                </div>
                <div className="team-wrapper--card">
                  <div className="team-wrapper--card-content">
                    <img src={picture7} alt="team" />
                  </div>
                </div>
                <div className="team-wrapper--card">
                  <div className="team-wrapper--card-content">
                    <img src={picture8} alt="team" />
                  </div>
                </div>

                <div className="team-wrapper--card">
                  <div className="team-wrapper--card-content border">
                    <p>
                      Our mission is to provide an extremely fast, high-quality
                      and exciting online payment experience to our main friends
                      - our customers
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}


            <div className="site__container about_container">

                    <h3 className="title">
                        <span>
                        Digimeths’
                        </span>
                        {` `}international and local payment services 
are convenient for all business types.
                    </h3>

                    <p className="description">
                    We provide user-friendly services and secure payment solutions with quality customer support. 
Our services include:
                    </p>

            </div>


          <CarouselHorVert>
            <div className="slide">
              <p style={{ padding: 8 }}>
                Subscription
                <br />
                business model
              </p>
            </div>
            <div className="slide">
              <p style={{ padding: 8 }}>
                Online
                <br />
                payments
              </p>
            </div>
            <div className="slide active">
              <p style={{ padding: 8 }}>
                One-click
                <br />
                payments
              </p>
            </div>
            <div className="slide">
              <p style={{ padding: 8 }}>
                Alternative
                <br />
                payments
              </p>
            </div>
            <div className="slide">
              <p style={{ padding: 8 }}>Fraud solution</p>
            </div>
          </CarouselHorVert>
        </section>

        <section className="alternative_payment-section typical__padding co-bg--black" id="contact-us">
          <div className="site__container">
            <div className="content-title">
              <h2>Contacts</h2>
            </div>
            <div className="contact_section--grid">
              <div className="contact_section--grid-item">
                <div className="contact_section--grid-item--row">
                  <div className="icon">
                    <img src={contactus_1} alt="c-icon"/>
                  </div>
                  <div className="box">
                    <p>
                      <span>Company: </span>
                      DIGITALMETHOD TECH LTD
                    </p>
                  </div>
                </div>
                <div className="contact_section--grid-item--row">
                  <div className="icon">
                  <img src={contactus_2} alt="c-icon"/>
                  </div>
                  <div className="box">
                    <p>
                      <span> Address: </span>
                      167-169 Great Portland Street, Fifth Floor, London, United Kingdom, W1W 5PF
                        <br />
                      <a href="tel:+44 771 947 42 59">+44 771 947 42 59</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="contact_section--grid-item">
                <div className="contact_section--grid-item--row">
                  <div className="icon">
                  <img src={contactus_3} alt="c-icon"/>
                  </div>
                  <div className="box">
                    <p>
                      In case of any questions regarding our paymemt services
                      please contact us by email
                    </p>
                    <a href="mailto:contact@digimeth.net">contact@digimeth.net</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Services
          title="Work at Digimeth Feels Perfect!"
          desc="Our goal is to create fantastic payment solutions:"
          border={true}
        />

        <GetStarted
          isGetStarted={true}
          title="High sales start with convenient payment services"
          description="Contact us to have more info! "
        />
      </article>
    </Layout>
  );
};

export default AboutPage;
